import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import Split from './Split';

const Footer = () => (
  <footer className="bg-footer">
    <div className="container">
      <Split>
        <div>
          <strong>Följ oss</strong>
          <div className="socials">
            <a className="default-link social-link" target="_blank" aria-label="facebook" href="https://www.facebook.com/SPIIK" rel="noreferrer"><FaFacebookSquare /></a>
            <a className="default-link social-link" target="_blank" aria-label="instagram" href="https://www.instagram.com/spiikkalmar/" rel="noreferrer"><FaInstagram /></a>
          </div>
        </div>
        <div>
          <strong>Kontakta oss</strong>
          <p>
            <a className="styled-link yellow-link" href="mailto:styrelsen@spiik.com">styrelsen@spiik.com</a>
          </p>
        </div>
        <div>
          <strong>Besök vårt kontor</strong>
          <p>
            Hus Magna, Våning 4
          </p>
          <p>
            Pedalstråket 7, 392 31 Kalmar
          </p>
        </div>
      </Split>
    </div>
  </footer>
);

export default Footer;
